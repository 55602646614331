.header {
    transition: all 0.3s ease-in-out;
  }
  
  .typing .header {
    position: absolute;
    top: 10px;
    left: 10px;
  }
  
  .typewriter {
    font-size: 40px;
    font-weight: 1000px;
    color: #fff;
    text-align: center;
    margin-top: 20px;
  }
  
  .cursor {
    display: inline-block;
    width: 1px;
    background-color: transparent;
    animation: blink 1s step-start infinite;
  }
  
  @keyframes blink {
    50% {
      opacity: 0;
    }
  }


  @media (max-width: 600px) {
    .header {
      padding: 5px;
    }
  
    .typewriter {
      font-size: 24px; /* Adjusted font size for better fit */
    }
  }
  
  
  