/* App.module.css */
.app {
  background-color: #171717;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 40px;
  color: white;
  width: 100%;
  height: 100%;
}
.landing {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 55vw;
  top: 20vw;
  width: 100%;
  transition: all 0.3s ease-in-out;
  position: relative;
  z-index: 1;
}
.light {
  width: 150px;
  height: 150px;
  z-index: 1;
}

.floor {
  position: absolute;
  width: 70vw;
  height: 10vw;
  top: 35vw;
  left: 13vw;
  border-radius: 764px;
  background: rgba(208, 175, 68, 0.475);
  filter: blur(100px);
  z-index: 0;
}
.cone {
  position: absolute;
  width: 0;
  height: 0;
  top: 27vw;
  left: 50%;
  border-left: 35vw solid transparent;
  border-right: 35vw solid transparent;
  border-bottom: 40vw solid rgba(208, 175, 68, 0.474);
  filter: blur(40px); /* Increased blur for a more pronounced effect */
  z-index: 0;
  transform: translate(-50%, -50%);  

}
.content {
  display: flex;
  flex-direction: row;
  width: 100%;
  transition: all 0.3s ease-in-out;
}