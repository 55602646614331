.container {
  text-align: center;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  max-height: 6vw;
}

.uploadContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.uploadText {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

.upload {
  display: none;
}

.uploadDiv {
  background-color: rgb(61, 126, 61);
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s;
}

.uploadDiv:hover {
  background-color: aquamarine;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
}

.uploadDiv:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.uploadDiv:hover:not([disabled]) {
  background-color: rgb(24, 199, 24);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
}

.message {
  margin-top: 10px;
  font-size: 14px;
  color: rgb(255, 0, 0);
}

@media (max-width: 600px) {
  .container {
    margin-left: 0;
    max-height: none;
  }

  .uploadContainer {
    margin-bottom: 20px;
  }

  .uploadDiv {
    padding: 8px 16px; /* Adjusted padding for better touch interaction on small screens */
    font-size: 14px; /* Adjusted font size for better fit */
  }
}
