.footer {
    background-color: #171717;
    color: white;
    display: flex;
    position: relative;
    padding: 20px;
    align-items: center;
    justify-content: center;
    height: 10vw;
    overflow: hidden;
}
.content {
    position: relative;
    z-index: 1;
    color: white;
    text-align: center;
    width: 100%;
    font-size: 12px;
    display: flex;
    flex-direction: column;
}
.row {
    display: flex;
    flex-direction: row;
    justify-content: space-around
}
.link {
    text-decoration: none;
    color: white;
    margin: 5px;
}

@media (max-width: 600px) {
    .footer {
      padding: 10px; /* Adjusted padding for better fit on small screens */
    }
  
    .content {
      font-size: 10px; /* Adjusted font size for better fit */
    }
  
    .row {
      flex-direction: column; /* Stack links vertically on small screens */
    }
  
    .link {
      margin: 3px; /* Adjusted margin for better fit */
    }
  }
  