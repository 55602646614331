.face {
    width: 100px;
    height: 100px;
    background-color: #222;
    border-radius: 50%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 30px 10px -10px rgba(0, 0, 0, 0.464);
  }
  .eye {
    width: 30px;
    height: 30px;
    background-color: white;
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .pupil {
    width: 10px;
    height: 10px;
    background-color: black;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
      

  @media (max-width: 600px) {
    .face {
      width: 80px;
      height: 80px;
      box-shadow: 0px 20px 10px -10px rgba(0, 0, 0, 0.464); /* Adjust shadow for smaller size */
    }
  
    .eye {
      width: 20px;
      height: 20px;
    }
  
    .pupil {
      width: 6px;
      height: 6px;
    }
  }
  
  