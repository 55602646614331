.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
    z-index: 1;
}
.chatBox {
    width: 90%;
    background-color: #333;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 4px 80px rgba(255, 255, 255, 0.2);
    z-index: 1;
}
.messages {
    flex-grow: 1;
    width: 98.5%;
    overflow-y: auto;
    margin-bottom: 20px;
    border: 2px solid rgb(169, 165, 165);
    border-radius: 20px;
    height: 85vh;
    padding: 10px;
    background: linear-gradient(to bottom, #333 0%, #171717 100%);
    
} 
.userMessage, .botMessage {
    padding: 10px;
    margin: 10px 0;
    border-radius: 8px;
    max-width: 100%;
    word-wrap: break-word;
    font-size: 16px;
}
.userMessage {
    align-self: flex-end;
    background-color: black;
    color: white;
    border: 1px solid #555; /* Add a border for better definition */
} 
.botMessage {
    align-self: flex-start;
    background-color: #655c5c;
    color: white;
    border: 1px solid #444; /* Add a border for better definition */
} 
.inputForm {
    display: flex;
    width: 98.5%;
    background-color: #171717;
    padding: 10px;
    border-radius: 10px; /* Add border-radius for consistency */
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
}
  
.input {
    width: 70%;
    padding: 10px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    font-size: 16px;
    background-color: #222; /* Match background color with the chatbox */
    color: white; /* Ensure text color is white for contrast */
}
  
.button {
    padding: 10px 20px;
    border: none;
    background-color: black;
    color: white;
    border-radius: 10px;
    border: solid 1px white;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Add subtle shadow for button */
}
  
.button:hover {
    background-color: #655c5c;
}

@media (max-width: 600px) {
    .container {
      padding: 10px;
    }
  
    .chatBox {
      width: 100%;
      padding: 15px;
    }
  
    .messages {
      height: 50vh; /* Adjusted height for better fit on small screens */
    }
  
    .input {
      width: 60%; /* Adjust width for better fit */
    }
  
    .button {
      padding: 8px 16px; /* Adjusted padding for better fit on small screens */
      font-size: 14px; /* Adjusted font size for better fit */
    }
  }
  
  